import React from "react";
import LessonLayout from "~/layouts/lesson";
import Introduction from "~/layouts/introduction";

import BSCSLogo from '~/images/lesson_2_opener.jpg';



const Page = () => {
  return (
      <LessonLayout
        current_step={-2}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <Introduction
          alt="tablet"
          image={BSCSLogo}
          description="In the last lesson, you learned about website features that can indicate quality. Looking at those features can help, but that is not enough. You still need to figure out whether the information is accurate with evidence to back up any claims. Now you will learn different ways to judge whether health and science information on the internet is correct—or whether it might have problems."
        />

      </LessonLayout>
  )
}

export default Page;
